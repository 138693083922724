/**
 * Export `banners keys`.
 */

export const bannersKeys = {
  aboutUs: 'about us',
  homepage: 'homepage',
  homepageMiddle: 'homepage_middle',
  humanResources: 'human_resources',
  innovation: 'innovation',
  sustainability: 'sustainability'
};
