/**
 * Module dependencies.
 */

import { ReactElement, useMemo } from 'react';
import { absoluteUrlResolver } from 'src/core/utils/url-resolver';
import { isProduction } from '@untile/react-components';
import { useRouter } from 'next/router';
import Head from 'next/head';

/**
 * Default title.
 */

const defaultTitle = 'Amtrol Alfa';

/**
 * Default locale.
 */

const defaultLocale = process.env.NEXT_PUBLIC_LOCALIZATION_DEFAULT_LOCALE;

/**
 * `Props` type.
 */

type Props = {
  description?: string;
  image?: string;
  keywords?: string;
  pageTitle?: string;
  title?: string;
};

/**
 * `Metatags` component.
 */

const Metatags = (props: Props): ReactElement => {
  const { description, image, keywords, pageTitle, title } = props;
  const router = useRouter();
  const pageUrl = useMemo(() => {
    const route = router.asPath.split(/[?#]/)[0];

    if (router.locale === defaultLocale) {
      return absoluteUrlResolver(route);
    }

    return absoluteUrlResolver(`/${router.locale}${route}`);
  }, [router]);

  const imageUrl = image ?? absoluteUrlResolver('/static/images/og-image.jpg');
  const normalizedPageTitle = useMemo(() => {
    if (pageTitle && pageTitle === defaultTitle) {
      return pageTitle;
    }

    if (pageTitle) {
      return `${pageTitle} - ${defaultTitle}`;
    }

    return defaultTitle;
  }, [pageTitle]);

  return (
    <Head>
      <title>{normalizedPageTitle}</title>

      {description && <meta
        content={description}
        name={'description'}
      />}

      {keywords && <meta
        content={keywords}
        name={'keywords'}
      />}

      <meta
        content={imageUrl}
        name={'image'}
      />

      {title && <meta
        content={title}
        property={'og:title'}
      />}

      {description && (
        <meta
          content={description}
          property={'og:description'}
        />
      )}

      <meta
        content={imageUrl}
        property={'og:image'}
      />

      <meta
        content={pageUrl}
        property={'og:url'}
      />

      <meta
        content={defaultTitle}
        property={'og:site_name'}
      />

      <meta
        content={'website'}
        property={'og:type'}
      />

      {title && <meta
        content={title}
        name={'twitter:title'}
      />}

      {description && (
        <meta
          content={description}
          name={'twitter:description'}
        />
      )}

      <meta
        content={imageUrl}
        name={'twitter:image'}
      />

      <meta
        content={'summary_large_image'}
        name={'twitter:card'}
      />

      <meta
        content={'Untile, hello@untile.pt'}
        name={'author'}
      />

      <meta
        content={defaultTitle}
        name={'copyright'}
      />

      <link
        href={pageUrl}
        rel={'canonical'}
      />

      {isProduction() && <meta
        content={'index, follow'}
        name={'robots'}
      />}
    </Head>
  );
};

/**
 * Export `Metatags` component.
 */

export default Metatags;
