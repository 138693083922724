/**
 * Module depenencies.
 */

import {
  Button,
  Display,
  GalleryNavigation,
  Image,
  Lightbox,
  PlayVideoButton,
  Slide,
  Slider,
  VideoPlayer
} from 'src/components/core';

import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { color, media, units, useBreakpoint } from '@untile/react-components';
import { detectMobile } from 'src/core/utils/devices';
import { ifProp, prop, theme } from 'styled-tools';
import Container from 'src/components/core/layout/container';
import compact from 'lodash/compact';
import includes from 'lodash/includes';
import map from 'lodash/map';
import size from 'lodash/size';
import styled, { css } from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * Export `Banner` type.
 */

export type Banner = {
  bgColor: string;
  description: string;
  imageDesktop: string;
  imageMobile?: string;
  linkLabel?: string;
  linkUrl?: string;
  title: string;
  videoUrl?: string;
};

/**
 * `Props` type.
 */

type Props = {
  banners: Banner[];
  className?: string;
  isMiddleBanner?: boolean;
};

/**
 * `slideTransition`.
 */

const slideTransition = 750;

/**
 * `Section` component.
 */

const Section = styled.section`
  position: relative;
  width: 100vw;
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  display: grid;
  grid-template-areas:
    'gallery gallery    gallery'
    '.       navigation .';
  grid-template-columns: ${theme('grid.gutterMobile')}px 1fr ${theme(
      'grid.gutterMobile'
    )}px;
  grid-template-rows: 1fr ${units(9)};
  padding: 0;

  ${media.min('sm')`
    max-height: 100vh;
  `}

  ${media.min('md')`
    grid-template-columns: 1fr 10fr 1fr;
    grid-template-rows: 1fr ${units(8)};
  `}
`;

/**
 * `Gallery` styled component.
 */

const Gallery = styled.div`
  grid-area: gallery;
  grid-row: 1 / 3;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

/**
 * `SlideCard` styled component.
 */

const SlideCard = styled.div<{
  bgColor: string;
  isMiddleBanner?: boolean;
}>`
  background-color: ${prop('bgColor')};
  display: grid;
  grid-template-areas:
    '.     .           .'
    '.     textWrapper .'
    'image image       image';
  grid-template-columns: ${theme('grid.gutterMobile')}px 1fr ${theme(
      'grid.gutterMobile'
    )}px;
  grid-template-rows:
    calc(${theme('dimensions.navbarHeightMobile')}px + ${units(4)})
    max-content 1fr;
  height: 208vw;
  position: relative;

  ${media.min('xs')`
    height: 190vw;
  `}

  ${media.min('sm')`
    height: 122vw;
  `}

  ${media.min('ms')`
    height: 88vw;
  `}

  ${media.min('md')`
    grid-template-areas:
      '. .           image .'
      '. textWrapper image .'
      '. .           image .';
    grid-template-columns: 1fr 4fr 6fr 1fr;
    grid-template-rows: calc(${theme('dimensions.navbarHeight')}px + ${units(
    2.5
  )}) max-content 1fr;
    height: 44vw;
  `}

  ${media.min('lg')`
    grid-template-rows: calc(${theme('dimensions.navbarHeight')}px + ${units(
    13
  )}) max-content 1fr;
  `}

  ${ifProp(
    'isMiddleBanner',
    css`
      grid-template-areas:
        'image image       image'
        '.     textWrapper .'
        '.     .           .';
      grid-template-rows: ${units(8)} max-content 1fr;
      min-height: 100vh;

      ${media.min('sm')`
      height: 122vw;
      min-height: unset;
    `}

      ${media.min('ms')`
      grid-template-areas:
        '. .           image'
        '. textWrapper image'
        '. .           image';
      grid-template-columns: 1fr 4fr 7fr;
      grid-template-rows: ${units(13.5)} max-content 1fr;
      height: 88vw;
    `}

    ${media.min('md')`
      height: 47vw;
    `}

    ${media.min('lg')`
      grid-template-rows: ${units(25)} max-content 1fr;
    `}
    `
  )}
`;

/**
 * `TextWrapper` styled component.
 */

const TextWrapper = styled.div`
  grid-area: textWrapper;
  z-index: 1;
`;

/**
 * `Subtitle` styled component.
 */

const Subtitle = styled(Display)`
  color: ${color('black')};
  margin-bottom: ${units(2)};
`;

/**
 * `ButtonWrapper` styled component.
 */

const ButtonWrapper = styled.div`
  grid-area: button;
  margin-bottom: ${units(4)};
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div<{ isMiddleBanner?: boolean }>`
  align-self: end;
  grid-area: image;
  overflow: hidden;
  padding-bottom: 94%;
  position: relative;

  ${media.min('md')`
    padding-bottom: 70%;
  `}

  ${ifProp(
    'isMiddleBanner',
    css`
      grid-column: 1 / -1;
      grid-row: 1 / -1;
      padding-bottom: 216.5%;

      ${media.min('ms')`
      padding-bottom: 130%;
    `}

      ${media.min('md')`
      padding-bottom: 50%;
    `}
    `
  )}
`;

/**
 * `MobileImageWrapper` styled component.
 */

const MobileImageWrapper = styled.div`
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;

  &::after {
    background: linear-gradient(
      180deg,
      ${color('white')} 37.58%,
      ${color.transparentize('white', 0)} 75.16%
    );
    bottom: 0;
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

/**
 * `GalleryNavigationWrapper` styled component.
 */

const GalleryNavigationWrapper = styled.div`
  grid-area: navigation;
  grid-row: 2;
  z-index: 2;
`;

/**
 * `StyledVideoPlayer` styled component.
 */

const StyledVideoPlayer = styled(VideoPlayer)`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100vh;
  pointer-events: none;
  position: relative;

  ${media.min('ms')`
    height: auto;
  `}

  &::after {
    background: linear-gradient(
      180deg,
      ${color('white')} 0%,
      ${color.transparentize('white', 0)} 75.16%
    );
    bottom: 0;
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

/**
 * `PlayVideoButtonWrapper` styled component.
 */

const PlayVideoButtonWrapper = styled.div`
  z-index: 1;

  button {
    background-color: ${color.transparentize('grey200', 0.4)};

    &::before {
      background-color: ${color.transparentize('grey200', 0.2)};
    }
  }
`;

/**
 * `BannerSection` component.
 */

const BannerSection = (props: Props): ReactElement | null => {
  const { banners, className, isMiddleBanner } = props;
  const { translate } = useTranslate();
  const isMobile = useBreakpoint('md', 'max');
  const sliderRef = useRef<any>();
  const total = size(banners);
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [isMobileDevice, setIsMobileDevice] = useState<boolean>();
  const [isOpenLightbox, setOpenLightbox] = useState<boolean>(false);
  const bannersWithVideoIndex = useMemo(() => {
    return compact(
      map(banners, ({ videoUrl }: Banner, index) => {
        return videoUrl && index;
      })
    );
  }, [banners]);

  const handleSliderAutoplay = useCallback((action: 'start' | 'stop') => {
    if (sliderRef && sliderRef.current) {
      if (action === 'stop') {
        sliderRef.current.swiper.autoplay.stop();

        return;
      }

      sliderRef.current.swiper.autoplay.start();
    }
  }, []);

  const handleSlideChange = useCallback(
    newIndex => {
      setActiveSlide(newIndex);

      if (includes(bannersWithVideoIndex, newIndex) && !isMobile) {
        handleSliderAutoplay('stop');

        return;
      }

      handleSliderAutoplay('start');
    },
    [bannersWithVideoIndex, handleSliderAutoplay, isMobile]
  );

  const handleClickDot = useCallback(
    index => {
      if (sliderRef && sliderRef.current) {
        sliderRef.current.swiper.slideTo(index);

        handleSlideChange(index);
      }
    },
    [handleSlideChange]
  );

  useEffect(() => {
    handleSliderAutoplay('start');
  }, [handleSliderAutoplay]);

  useEffect(() => {
    setIsMobileDevice(detectMobile());
  }, []);

  return (
    <>
      <Section className={className}>
        <StyledContainer>
          <Gallery>
            <Slider
              autoplay={{
                delay: 6000,
                disableOnInteraction: true
              }}
              onSlideChange={swiper => handleSlideChange(swiper.activeIndex)}
              parallax
              ref={sliderRef}
              slidesPerView={1}
              speed={slideTransition}
            >
              {map(banners, (banner, index) => {
                const {
                  bgColor,
                  description,
                  imageDesktop,
                  imageMobile,
                  linkLabel,
                  linkUrl,
                  title,
                  videoUrl
                } = banner;

                return (
                  <Slide key={index}>
                    <SlideCard
                      bgColor={bgColor}
                      isMiddleBanner={isMiddleBanner}
                    >
                      <TextWrapper>
                        <Display data-swiper-parallax={-500}>
                          {translate(title)}
                        </Display>

                        <Subtitle
                          data-swiper-parallax={-250}
                          size={'medium'}
                        >
                          {translate(description)}
                        </Subtitle>

                        {linkLabel && linkUrl && (
                          <ButtonWrapper data-swiper-parallax={-50}>
                            <Button
                              colorTheme={'secondary'}
                              {...(linkUrl && { href: linkUrl })}
                            >
                              {translate(linkLabel)}
                            </Button>
                          </ButtonWrapper>
                        )}
                      </TextWrapper>

                      {videoUrl ? (
                        <>
                          {isMobileDevice ? (
                            <>
                              <MobileImageWrapper>
                                <Image
                                  alt={description}
                                  fill
                                  src={imageMobile}
                                  style={{ objectFit: 'cover' }}
                                />
                              </MobileImageWrapper>

                              <PlayVideoButtonWrapper
                                onClick={() => setOpenLightbox(true)}
                              >
                                <PlayVideoButton />
                              </PlayVideoButtonWrapper>

                              <Lightbox
                                isOpen={isOpenLightbox}
                                onClose={() => setOpenLightbox(false)}
                                videoUrl={videoUrl}
                              />
                            </>
                          ) : (
                            <StyledVideoPlayer
                              controls={false}
                              loop
                              muted
                              playing={index === activeSlide}
                              url={videoUrl}
                              wait={slideTransition - 150}
                            />
                          )}
                        </>
                      ) : (
                        <ImageWrapper
                          {...(!isMobile
                            ? {
                                'data-scroll': true,
                                'data-scroll-speed': -2,
                                'data-swiper-parallax': -250
                              }
                            : {
                                'data-scroll': true,
                                'data-swiper-parallax': 0
                              })}
                          isMiddleBanner={isMiddleBanner}
                        >
                          <Image
                            alt={description}
                            fill
                            src={
                              (isMobile && (imageMobile ?? imageDesktop)) ||
                              imageDesktop
                            }
                            style={{
                              objectFit: 'contain',
                              ...(isMiddleBanner && { objectPosition: 'right' })
                            }}
                          />
                        </ImageWrapper>
                      )}
                    </SlideCard>
                  </Slide>
                );
              })}
            </Slider>
          </Gallery>

          <GalleryNavigationWrapper>
            {total > 1 && (
              <GalleryNavigation
                activeDot={activeSlide}
                onClickDot={handleClickDot}
                total={total}
              />
            )}
          </GalleryNavigationWrapper>
        </StyledContainer>
      </Section>
    </>
  );
};

/**
 * Export `BannerSection` component.
 */

export default BannerSection;
