/**
 * Module dependencies.
 */

import { Banner } from 'src/components/sections/banner-section';
import {
  QueryObserverBaseResult,
  QueryObserverOptions,
  useQuery
} from 'react-query';
import { fetchBanner } from './fetch-banner';
import { useMemo } from 'react';
import { useRouter } from 'next/router';

/**
 * `Result` type.
 */

type Result = QueryObserverBaseResult<Banner[], unknown>;

/**
 * `Options` type.
 */

type Options = QueryObserverOptions<Banner[]> & {
  slug: string;
};

/**
 * Action type.
 */

const actionType = ({ locale, slug }): string => {
  return `${locale}-banners-${slug}`;
};

/**
 * `useBanner` hook.
 */

function useBanner(options?: Options): Result {
  const { slug, ...rest } = options;
  const { locale } = useRouter();
  const variables = useMemo(
    () => ({
      locale,
      slug
    }),
    [locale, slug]
  );

  const result = useQuery(
    [actionType(variables), variables],
    () => {
      return fetchBanner(variables);
    },
    { ...rest }
  );

  return result;
}

/**
 * Export `useBanner` hook.
 */

export default useBanner;
